module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://business.centurylink.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"chatEnabled":true,"analyticsId":"GTM-W8P8MDC","brandTokens":["CTLB","DTVB","CTL"],"mapiBrandToken":"SHO","siteName":"business.centurylink","alternateName":"business.centurylink.com","siteURL":"https://business.centurylink.com","defaultTitleTemplate":"","defaultPhone":"8336601244","phoneSymbol":"-","defaultPromoCode":"155790","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046080","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"business.centurylink","short_name":"business.centurylink","start_url":"/","background_color":"#48D597","theme_color":"#48D597","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28a6ace9291e87b01d77ad9c07486849"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
